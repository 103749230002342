<template>
  <div
    class="lxp-dashboard__feedback d-flex justify-content-center align-items-stretch"
  >
    <div
      :class="{
        'align-self-center': true,
        'mr-4': language !== 'ar',
        'ml-4': language === 'ar'
      }"
    >
      <img src="@/assets/images/dashboard/feedback-icon.svg" alt="feedback" />
    </div>
    <div class="align-self-center">
      <div class="section-title">{{ $t("dashboard.question") }}</div>
      <Button
        variant="outline-info"
        class="text-uppercase"
        @click="$emit('onFeedbackClick')"
        >{{ $t("dashboard.feedback.button") }}</Button
      >
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["language"])
  }
};
</script>
<style lang="scss">
.lxp-dashboard__feedback {
  padding: 20px 30px 0;
  .section-title {
    color: #25282b;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .btn {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
  }
}
</style>
